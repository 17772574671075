.item {
  font-family: "Lobster", "Pacifico", cursive;
  color: #222;
  line-height: 1.4;
  background: url(../graphic/backgrounds/sq.png) no-repeat;
  background-size: 230px;

  height: 195px;
  width: 230px;

  margin-top: 9px;
  margin-left: -49px;
  padding: 0 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  hyphens: auto;
}

.item:nth-child(2n) {
  margin-top: -95px;
  margin-left: -48px;
}

.item span {
  font-size: 29px;
  line-height: 1;
}
