.sqtl {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../graphic/backgrounds/squares_t.png) no-repeat;
}

.sqmenu {
  font-family: "Lobster", "Pacifico", cursive;
  color: #222;
  text-align: center;
  line-height: 1.4;
  width: 265px;
  overflow: hidden;
  position: absolute;

  height: 160px;
  top: 0;
  left: -3px;
}

.sqmenu span {
  display: block;
  font-size: 25px;
  padding-top: 40px;
}

.bodycontent {
  min-height: 100%;
  background: rgba(255, 255, 255, 0.9);
  padding: 300px 50px;
  font-family: sans-serif;
}

.pagedata {
  overflow: hidden;
  max-width: 1200px;
  margin: auto;
}

.pagedata h1 {
  font-family: "Lobster", "Pacifico", cursive;
  font-size: 50px;
}

.pagedata img {
  padding: 0.5rem;
  background: #fff;
  border-radius: 5px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 1.5rem;
}

.pagedata figure {
  background: #fff;
  margin-bottom: 1.5rem;
  padding: 0.5rem;
  border-radius: 5px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  font-size: 0.8rem;
  text-align: center;
}
.pagedata figcaption {
  border-top: 1px solid #ddd;
  padding: 1rem 1rem 0.5rem;
}

.pagedata figure img {
  padding: 0;
  box-shadow: none;
}
