.sqbr {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: url(../graphic/backgrounds/squares_br.png) no-repeat;
  background-position: bottom right;
  opacity: 0.5;
}

.sqtl {
  position: absolute;
  top: 0;
  left: 0;
  width: 1200px;
  background: url(../graphic/backgrounds/squares_t.png) no-repeat;
  background-size: 960px;

  display: flex;
  flex-wrap: wrap;

  padding-top: 140px;
  padding-left: 50px;

  transform: scale(0.85);
  transform-origin: top left;
}

.heading {
  position: absolute;
  bottom: 100px;
  left: 160px;
  font-family: "Lobster", "Pacifico", cursive;
  font-size: 80px;
  color: #fff;
  text-shadow: 3px 0 10px #000000;
  text-align: left;
}

.sqmenu {
  font-family: "Lobster", "Pacifico", cursive;
  color: #222;
  text-align: center;
  line-height: 1.4;
  width: 206px;
  overflow: hidden;
  position: absolute;

  height: 126px;
  top: 0;
  left: -10px;
  z-index: 99;
}

.sqmenu span {
  display: block;
  font-size: 25px;
  padding-top: 30px;
}

@media all and (max-height: 1200px) {
  .heading {
    bottom: 50px;
  }
}
