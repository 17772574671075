@import url("https://fonts.googleapis.com/css?family=Lobster");
@import url("https://fonts.googleapis.com/css?family=Roboto:200,300,400");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #f2f2f2;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  color: #000;
  font-family: "Roboto", sans-serif;
  background-image: url("./graphic/backgrounds/963A3977.jpg");
}
body.info-page {
  background-image: url("./graphic/backgrounds/church_groundview.jpg");
}

::-webkit-scrollbar {
  display: none;
}

img {
  max-width: 100%;
  height: auto;
  width: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
  font-size: 18px;
  line-height: 1.2;
  margin-bottom: 2rem;
  font-family: "Lobster", "Pacifico", cursive;
}

h2 {
  font-size: 40px;
  margin-bottom: 1rem;
}

a {
  text-decoration: none;
}

.alink {
  cursor: pointer;
}

a:hover,
.alink:hover {
  opacity: 0.8;
}

ul,
li {
  list-style-type: none;
}

p {
  line-height: 1.5;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 300;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  cursor: default;
}

/* photoswipe */
.gallery {
  max-width: 1200px;
  margin: auto;
}

.gallery h2 {
  margin-bottom: 2rem;
}

.pswp-thumbnails {
  display: flex;
  flex-wrap: wrap;
}

.pswp-thumbnail {
  padding-right: 20px;
  padding-bottom: 20px;
  width: 25%;
}

.pswp-thumbnail img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.pswp-thumbnail > div {
  position: relative;
  width: 100%;
  padding-bottom: 70%;
  height: 0;
}

.small-grid-gallery {
  display: flex;
  flex-wrap: wrap;
}

.small-grid-gallery > div {
  width: 25%;
  display: flex;
}

.small-grid-gallery > div > figure {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.small-grid-gallery > div > figure > img {
  margin: auto;
  max-height: 150px;
  padding: 15px;
}

.small-grid-gallery > div > figure > figcaption {
  margin-top: auto;
}
